// **  Initial State
const initialState = {
  spendOpt: null,
  budOpt: null,
  spendOptInfo: null
}

const optimizerReducer = (state = initialState, action) => {

  switch (action.type) {

    case 'HANDLE_SPEND_OPT':
      return {
        ...state,
        spendOpt: action.spendOpt
      }

    case 'HANDLE_BUD_OPT':
      return {
        ...state,
        budOpt: action.budOpt
      }

    case 'RESET_SPEND_OPT_INFO':
      return {
        ...state,
        spendOptInfo: null
      }

    // Set the spendOptInfo and also save the
    // constraints dict 
    case 'SET_SPEND_OPT_INFO':
      return {
        ...state,
        spendOptInfo: action.info
      }

    case "HANDLE_SWITCH_PROJECT":
      return {
        ...initialState
      }

    default:
      return state
  }
}

export default optimizerReducer
