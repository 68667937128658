// ** Redux Imports
import { combineReducers } from 'redux'

// ** Reducers Imports
import auth from './auth'
import navbar from './navbar'
import layout from './layout'
import company from './company'
import analytics from './analytics'
import optimizer from './optimizer'
import users from './users'
import project from './project'

const rootReducer = combineReducers({
  auth,
  navbar,
  layout,
  company,
  analytics,
  optimizer,
  users,
  project
})

export default rootReducer
