// ** Redux, Thunk & Root Reducer Imports
import thunk from 'redux-thunk'
import createDebounce from 'redux-debounced'
import rootReducer from '../reducers/rootReducer'
import { createStore, applyMiddleware, compose } from 'redux'
import { ReduxStorageManager } from '../storage/reduxStorageManager'

// ** init middleware
const middleware = [thunk, createDebounce()]

// ** Dev Tools
const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose

// ** Create store
const persistedState = ReduxStorageManager.loadState();
const store = createStore(rootReducer, persistedState, composeEnhancers(applyMiddleware(...middleware)))

store.subscribe(() => {
    ReduxStorageManager.saveState(store.getState());
  });

export { store }
