import { Spinner, Row, Col } from "reactstrap"

const FallbackSpinner = () => {
  return (
    <div className='fallback-spinner vh-100'>
      <div className="loading">
        <Col className="align-items-center justify-content-center match-height">
          <Row>
            <Spinner color='primary' size='ml' className="margin-left-spinner" />
          </Row>
        </Col>
      </div>
    </div>
  )
}

export default FallbackSpinner