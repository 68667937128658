// ** Initial State
const initialState = {
  settings: null
}

const companyReducer = (state = initialState, action) => {

  switch (action.type) {

    case "HANDLE_GET_PROJECT_SETTINGS":
      return {
        ...state,
        settings: action.settings
      }

    case "HANDLE_RESET_PROJECT_SETTINGS":
      return {
        ...initialState
      }

    case "HANDLE_SWITCH_PROJECT":
      return {
        ...initialState
      }
    
    default:
      return state
  }
}

export default companyReducer
