import ResourceStatus from '@store/ResourceStatus'

// ** Initial State
const initialState = {
  selectedMetrics: null,
  startDate: null,
  endDate: null,
  projectItems: null,
  selectedItems: null,
  tableData: null,
  graphData: null,
  graphDataStatus: ResourceStatus.NotLoaded,

  // Views
  viewsStatus: ResourceStatus.NotLoaded,
  currentViewStatus: ResourceStatus.NotLoaded,
  availableColumnsStatus: ResourceStatus.NotLoaded,
  defaultViews: null,
  customViews: null,
  currentViewId: null,
  availableColumns: null
}

const analyticsReducer = (state = initialState, action) => {

  switch (action.type) {

    case "SET_SELECTED_METRICS":

      return {
        ...state,
        selectedMetrics: action.metrics
      }

    case "SET_SELECTED_DATES":

      return {
        ...state,
        startDate: action.startDate,
        endDate: action.endDate
      }

    case "UPDATE_PROJECT_ITEMS":

      return {
        ...state,
        projectItems: action.projectItems
      }

    case "RESET_GRAPH_DATA":

      return {
        ...state,
        graphData: null,
        graphDataStatus: ResourceStatus.NotLoaded
      }

    case "RESET_TABLE_DATA":

      return {
        ...state,
        tableData: null
      }

    case "SET_GRAPH_DATA":

      return {
        ...state,
        graphData: action.graphData,
        graphDataStatus: ResourceStatus.Loaded
        
      }

    case "SET_TABLE_DATA":

      return {
        ...state,
        tableData: action.tableData
      }

    case "SET_SELECTED_ITEMS":

      if (state.selectedItems === null) {
        return {
          ...state,
          selectedItems: action.itemsDict
        }
      }

      return {
        ...state,
        selectedItems: {
          ...state.selectedItems,
          ...action.itemsDict
        }
      }

    case "RESET_VIEWS":
      return {
        ...state,
        defaultViews: null,
        customViews: null,
        viewsStatus: ResourceStatus.NotLoaded
      }

    case "LOADING_VIEWS":
      return {
        ...state,
        viewsStatus: ResourceStatus.Loading
      }

    case "SET_VIEWS":
      return {
        ...state,
        defaultViews: action.defaultViews,
        customViews: action.customViews,
        viewsStatus: ResourceStatus.Loaded
      }

    case "RESET_CURRENT_VIEW":
      return {
        ...state,
        currentViewId: null,
        currentViewStatus: ResourceStatus.NotLoaded
      }

    case "LOADING_CURRENT_VIEW":
      return {
        ...state,
        currentViewStatus: ResourceStatus.Loading
      }

    case "SET_CURRENT_VIEW":
      return {
        ...state,
        currentViewId: action.currentViewId,
        currentViewStatus: ResourceStatus.Loaded
      }

    case "RESET_AVAILABLE_COLUMNS":
      return {
        ...state,
        availableColumns: null,
        availableColumnsStatus: ResourceStatus.NotLoaded
      }

    case "LOADING_AVAILABLE_COLUMNS":
      return {
        ...state,
        availableColumnsStatus: ResourceStatus.Loading
      }

    case "SET_AVAILABLE_COLUMNS":
      return {
        ...state,
        availableColumns: action.availableColumns,
        availableColumnsStatus: ResourceStatus.Loaded
      }

    case "HANDLE_SWITCH_PROJECT":
      return {
        ...initialState
      }

    default:
      return state
  }
}

export default analyticsReducer
