import React from "react"
import { CookieBanner } from "@palmabit/react-cookie-law"
import { useIntl } from "react-intl"

const CookieConsent = () => {
  const intl = useIntl()

  return (
    <CookieBanner
      message={intl.formatMessage({id: 'CookieMessage'})}
      wholeDomain={true}
      policyLink="/terms-and-conditions"
      privacyPolicyLinkText={intl.formatMessage({id: 'Cookie Policy'})}
      necessaryOptionText={intl.formatMessage({id: 'Necessary'})}
      preferencesOptionText={intl.formatMessage({id: 'Preferences'})}
      statisticsOptionText={intl.formatMessage({id: 'Statistics'})}
      marketingOptionText={intl.formatMessage({id: 'Marketing'})}
      acceptButtonText={intl.formatMessage({id: 'Accept'})}
      declineButtonText={intl.formatMessage({id: 'Decline'})}
      managePreferencesButtonText={intl.formatMessage({id: 'Manage my cookies'})}
      showDeclineButton={true}
      dismissOnScroll={false}
      showPreferencesOption={true}
      showStatisticsOption={true}
      showMarketingOption={true}
      preferencesDefaultChecked = {true}
      statisticsDefaultChecked = {true}
      marketingDefaultChecked = {true}
      // onAccept={() => console.log("onAccept")}
      // onAcceptPreferences={() => console.log("onAcceptPreferences")}
      // onAcceptStatistics={() => console.log("onAcceptStatistics")}
      // onAcceptMarketing={() => console.log("onAcceptMarketing")}
      // onDeclinePreferences={() => console.log("onDeclinePreferences")}
      // onDeclineStatistics={() => console.log("onDeclineStatistics")}
      // onDeclineMarketing={() => console.log("onDeclineMarketing")}

      styles={{
        dialog: {
          position: 'fixed',
          bottom: '0',
          left: '0',
          right: '0',
          zIndex: '100000',
          backgroundColor: '#cce0fd',
          padding: '10px'
        }
      }}
    /> 
  )
}
export default CookieConsent
