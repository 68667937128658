// ** Initial State
const initialState = {
  companyId: null,
  isActivated: null,
  role: null,
  advertisers: null,
  projects: null,
  selectedAdvertiser: null,
  selectedProject: null
}

const companyReducer = (state = initialState, action) => {

  switch (action.type) {

    case "HANDLE_GET_COMPANIES":

      if (action.company === null) {
        return {
          ...state,
          isActivated: false
        }
      }

      const company = action.company
      return {
        ...state,
        companyId: company.company_id.$oid,
        name: company.company_name,
        address: company.company_address,
        vat: company.company_vat,
        isActivated: true,
        role: company.role,
        advertisers: company.advertisers
      }

    case "HANDLE_GET_PROJECTS":

      return {
        ...state,
        projects: action.projects.map(p => {
          // Rename the keys to be more js-compliant
          delete Object.assign(p, {useConversionValue: p.use_conversion_value}).use_conversion_value
          delete Object.assign(p, {conversionValue: p.conversion_value}).conversion_value
          return p
        })
      }

    case "HANDLE_SELECT_ADVERTISER":

      const advertiser = action.advertiser

      // If it's the same advertiser as before, don't
      // do anything
      if (advertiser === state.selectedAdvertiser) {
        return {...state}
      }

      return {
        ...state,
        projects: null,
        selectedAdvertiser: advertiser,
        selectedProject: null
      }

    case "HANDLE_SWITCH_PROJECT":

      const project = action.project

      return {
        ...state,
        selectedProject: project
      }

    case "HANDLE_RESET_COMPANY":

      return {
        ...initialState
      }
    
    case "HANDLE_RESET_ADVERTISERS":

      return {
        ...state,
        advertisers: initialState.advertisers
      }

    default:
      return state
  }
}

export default companyReducer
