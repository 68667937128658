// **  Initial State
const initialState = {
  isAuthenticated: null,
  verificationUsername: null,
  attributes: null
}

const authReducer = (state = initialState, action) => {

  switch (action.type) {

    case 'AUTH': 

      return {
        ...state,
        isAuthenticated: action.data !== null,
        verificationUsername: null,
        attributes: action.data
      }
    
    case 'TEMP_AUTH':

      return {
        ...state,
        isAuthenticated: false,
        verificationUsername: action.data,
        attributes: null
      }

    case 'LOGOUT':

      const obj = { ...action }
      delete obj.type

      return { 
        ...state,
        isAuthenticated: false,
        verificationUsername: null,
        attributes: null,
        ...obj 
      }

    default:
      return state
  }
}

export default authReducer
