// ** Initial State
const initialState = {
  companyUsers: [],
  advertiserUsers: [],
  projectUsers: []
}

const userReducer = (state = initialState, action) => {

  switch (action.type) {

    case "HANDLE_GET_USERS_IN_COMPANY":

      return {
        ...state,
        companyUsers: action.users
      }

    case "HANDLE_GET_USERS_IN_ADVERTISER":

      return {
        ...state,
        advertiserUsers: action.users
      }

    case "HANDLE_GET_USERS_IN_PROJECT":

      return {
        ...state,
        projectUsers: action.users
      }

    case "HANDLE_RESET_USERS_IN_COMPANY":

      return {
        ...state,
        companyUsers: []
      }

    case "HANDLE_RESET_USERS_IN_ADVERTISER":

      return {
        ...state,
        advertiserUsers: []
      }

    case "HANDLE_RESET_USERS_IN_PROJECT":

      return {
        ...state,
        projectUsers: []
      }

    default:
      return state
  }
}

export default userReducer
