const REDUX_STORAGE_KEY = "adcube_state"

const loadState = () => {
    let state = {};
    try {
      const serializedState = sessionStorage.getItem(REDUX_STORAGE_KEY);
      if (serializedState === null) {
        state = {}
      } else {
        state = JSON.parse(serializedState);
      }
    } catch (error) {
      state = {}
    }
    return {company: state.company};
  };
  
  const saveState = (state) => {
    try {
      const serializedState = JSON.stringify(state);
      sessionStorage.setItem(REDUX_STORAGE_KEY, serializedState);
    } catch (error) {
    }
  };

  export const ReduxStorageManager = {
    loadState,
    saveState
  }